<template>
    <div>
        <div class="section-heading">Contact Events</div>
        <div v-if="contactEventDetail.contact_event_id == 'new'" class="contact-event-detail">
            <label>Event Type</label>
            <select v-model="contactEventDetail.contact_event_type_id">
                <option value=''>Select...</option>
                <option v-for="(item, index) in contactEventTypes" :value="item.contact_event_type_id">{{ item.description }}</option>
            </select>
            <label>Content</label>
            <textarea v-model="contactEventDetail.content"></textarea>
            <div class="contact-event-detail-controls">
                <div class="button cancel" @click="closeDetail()">Cancel</div>
                <div class="button" @click="saveDetail()"><LabelWithLoadingIcon :loading="loading" label="Save Event"/></div>
                <Message :message="message" :error="errorStatus" delay="3" :key="message" />
            </div>
        </div>
        <div v-else-if="contactEventDetail.contact_event_id" class="contact-event-detail">
            <div class="button button-dark button-tiny contact-event-detail-close" @click="closeDetail()">X</div>
            <div class="content-event-detail-heading">
                <div>
                    <label>Date</label>
                    <div>{{ contactEventDetail.date }}</div>
                </div>
                <div>
                    <label>Time</label>
                    <div>{{ contactEventDetail.time }}</div>
                </div>
                <div>
                    <label>Event Type</label>
                    <div>{{ contactEventDetail.description }}</div>
                </div>
                <div v-if="contactEventDetail.user_full_name">
                    <label>By</label>
                    <div>{{ contactEventDetail.user_full_name }}</div>
                </div>
            </div>
            <div v-for="(content, label) in contactEventDetail.data">
                <label>{{ localCaptializeWords(label) }}</label>
                <div>{{ content }}</div>
            </div>
        </div>
        <div v-else>
            <div class="contact-events-heading">
                <input type="search" placeholder="Filter..." v-model="search_term" />
                <div class="button button-dark" @click="addDetail()"><font-awesome-icon icon="plus" /></div>
            </div>
            <div v-for="(item, index) in filteredList" class="contact-event-list" @click="showDetail(item.contact_event_id)">
                <div>{{ item.date }}</div>
                <div>{{ item.time }}</div>
                <div>{{ item.description }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import Message from '@/components/Message.vue'
import LabelWithLoadingIcon from '@/components/LabelWithLoadingIcon.vue'
import { mapActions } from 'vuex'
export default {
	name: 'contact-events',
	props: ['contact_id'],
    components: {
        Message,
        LabelWithLoadingIcon
    },
    data () {
        return {
			contactEvents: [],
            contactEventDetail: {},
            contactEventTypes: [],
            search_term: '',
            message: '',
            errorStatus: false,
            loading: false
	    }
    },
    created() {
        this.getContactEventTypes()
        if (this.contact_id != 'new') {
            this.getList()
        }
	},
	computed: {
        filteredList() {
            return this.contactEvents.filter(item => item.description.toLowerCase().indexOf(this.search_term.toLowerCase()) > -1)
        }
	},
	methods: {
        ...mapActions([
            'apiCall', // map `this.apiCall()` to `this.$store.dispatch('apiCall')`
        ]),
        localCaptializeWords(words) {
            return this.caplitalizeWords(words)
        },
        getContactEventTypes() {
    		this.apiCall({ destination: 'get_list_contact_event_types' }).then(obj => {
    			if (obj.status == 'success') {
                    this.contactEventTypes = obj.contact_event_types
    			} else {
					this.message = obj.message
				}
    		})
        },
        getList() {
		    this.message = ''
            this.errorStatus = false
			this.loading = true
    		this.apiCall({ destination: 'get_list_contact_events', data: { contact_id: this.contact_id } }).then(obj => {
				this.loading = false
    			if (obj.status == 'success') {
                    this.contactEvents = obj.contact_events
    			} else {
					this.message = obj.message
                    this.errorStatus = true
				}
    		})
        },
        showDetail(contactEventId) {
            for (let i = 0; i < this.contactEvents.length; i++) {
                if (this.contactEvents[i].contact_event_id == contactEventId) {
                    this.contactEventDetail = this.contactEvents[i]
                    break;
                }
            }
        },
        closeDetail() {
            // NOTE: if editing, has item been saved?
            this.contactEventDetail = {}
		    this.message = ''
            this.errorStatus = false
        },
        addDetail() {
            this.contactEventDetail.contact_id = this.contact_id
            this.contactEventDetail.contact_event_id = 'new'
            this.contactEventDetail.contact_event_type_id = ''
            this.contactEventDetail.content = ''
        },
        saveDetail() {
		    this.message = ''
            this.errorStatus = false
            if (!this.contactEventDetail.contact_event_type_id || !this.contactEventDetail.content) {
                this.message = 'Event Type and Content are required'
                this.errorStatus = true
                return
            }
			this.loading = true
    		this.apiCall({ destination: 'save_item_contact_event', data: this.contactEventDetail }).then(obj => {
				this.loading = false
    			if (obj.status == 'success') {
                    this.contactEventDetail = {}
                    this.getList()
    			} else {
					this.message = obj.message
                    this.errorStatus = true
				}
    		})
        }
	}
}
</script>
<style lang="scss" scoped>
    @import '../styles/_colors.scss';
    .contact-events-heading {
        display: grid;
        grid-template-columns: 1fr 2.5em;
        grid-column-gap: 2em;
        margin: 1em 0 0 0;
        input { font-size: 0.9em; }
        .button { font-size: 0.7em; height: 2.75em; }
    }
    .contact-event-list {
        padding: 0.25em;
        border-top: 1px dotted #ccc;
    	@media all and (min-width: 960px) {
            display: grid;
            grid-template-columns: 5.5em 4em 1fr;
            grid-column-gap: 1em;
    	}

    }
    .contact-event-list:hover {
        cursor: pointer;
        background-color: $highlightBackgroud;
    }
    .contact-event-detail {
        position: relative;
        label {
            display: block;
            margin: 1em 0 0 0;
        }
        textarea { height: 9em; }
    }
    .content-event-detail-heading {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-gap: 1em;
    }
    .contact-event-detail-close {
        position: absolute;
        top: 1em;
        right: 0;
    }
    .contact-event-detail-controls {
        .button { margin: 0 1em 0 0;}
    }
</style>
