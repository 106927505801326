<template>
    <div class="modal">
        <div class="modal-inner modal-inner-large">
            <div class="modal-title">Send an Email</div>
            <div class="modal-inner-content">
                <div class="label-input-grid">
                    <label>From</label>
                    <div>{{ email.from }}</div>
                    <label>To</label>
                    <div>{{ email.to }}</div>
                    <LabelRequired label="Subject" :condition="email.subject"/>
                    <input type="text" v-model="email.subject" />
                </div>
                <hr>
                <LabelRequired label="Content" :condition="email.message"/>
                <textarea v-model="email.message"></textarea>
            </div>
            <div class="modal-controls control-buttons">
                <div class="button cancel" @click="cancelEmail()">Cancel</div>
                <div v-if="emailPending" class="button" @click="sendEmail()"><LabelWithLoadingIcon :loading="sending" label="Send Email" /></div>
                <Message :message="message" :error="errorStatus" delay="3" :key="message" />
            </div>
        </div>
    </div>
</template>

<script>
import LabelWithLoadingIcon from '@/components/LabelWithLoadingIcon.vue'
import LabelRequired from '@/components/LabelRequired.vue'
import Message from '@/components/Message.vue'
import { mapActions } from 'vuex'
export default {
	name: 'send-email',
	props: ['contact_id'],
    components: {
		LabelWithLoadingIcon,
        LabelRequired,
        Message
    },
    data () {
        return {
            loading: false,
            email: {
                contact_id: this.contact_id,
                from: '',
                to: '',
                subject: '',
                message: ''
            },
            sending: false,
            message: '',
            errorStatus: false,
            emailPending: true
	    }
    },
    created() {
        this.getEmailToFrom()
	},
	methods: {
        ...mapActions([
            'apiCall', // map `this.apiCall()` to `this.$store.dispatch('apiCall')`
        ]),
        getEmailToFrom() {
		    this.message = ''
            this.errorStatus = false
			this.loading = true
    		this.apiCall({ destination: 'get_item_contact_email_to_from', data: { contact_id: this.contact_id } }).then(obj => {
				this.loading = false
    			if (obj.status == 'success') {
					this.email.from = obj.email_to_from.from
					this.email.to = obj.email_to_from.to
    			} else {
					this.message = obj.message
                    this.errorStatus = true
				}
    		})
        },
        cancelEmail() {
        	this.$emit('cancel')
            this.message = ''
            this.errorStatus = false
        },
        sendEmail() {
            if (this.sending) { return }
            this.message = ''
            this.errorStatus = false
            if (!this.email.subject) {
                this.message = 'Please enter an email Subject'
                this.errorStatus = true
                return
            }
            if (this.email.message.length < 10) {
                this.message = 'Please enter the email content'
                this.errorStatus = true
                return
            }
            this.sending = true
    		this.apiCall({ destination: 'send_contact_email', data: this.email }).then(obj => {
				this.sending = false
    			if (obj.status == 'success') {
                    this.emailPending = false
                    this.message = "Email sent!"
                    setTimeout(() => {
                        this.$emit('sent')
				        this.email = {}
                    }, 2000);
    			} else {
					this.message = obj.message
                    this.errorStatus = true
				}
    		})
        }
	}
}
</script>
